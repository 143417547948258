import type { FC } from 'react';
import React, { useContext, useCallback } from 'react';
import { styled } from '@compiled/react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import type { SearchCSS } from '@atlaskit/atlassian-navigation';
import { useRenderServerPlaceholder } from '@confluence/ssr-utilities';
import { ProductSearchInputSkeletonInteractiveSSR } from '@atlassian/product-search-dialog/skeleton';
import { NavigationSearchDialog } from '@confluence/navigation-search-dialog';
import { useRouteDataRef } from '@confluence/route-manager';
import { START_TOUCH } from '@confluence/navdex';
import { AppNavigationContext } from '@confluence/app-navigation-context';
import { CONTEXT_PATH } from '@confluence/named-routes/src';
import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledContainer = styled.div({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	position: 'relative',
});

type SearchComponentProps = {
	theme?: SearchCSS;
};

export const SearchComponent: FC<SearchComponentProps> = ({ theme }) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const routeDataRef = useRouteDataRef();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { resetStickySearchRef } = useContext(AppNavigationContext);
	const renderServerPlaceholder = useRenderServerPlaceholder();

	const fireAnalytics = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'globalNavigation',
				action: 'clicked',
				actionSubject: 'navigationItem',
				actionSubjectId: 'quickSearch',
				attributes: {
					selectedItemPageContext: routeDataRef.current?.routeName,
					navigationLayer: 'global',
					navVersion: '3',
					navdexPointType: START_TOUCH,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, routeDataRef]);

	return (
		<StyledContainer
			data-testid="app-navigation-search"
			data-vc="app-navigation-search"
			{...ssrPlaceholderIdProp}
			onClick={fireAnalytics}
		>
			{renderServerPlaceholder ? (
				<ProductSearchInputSkeletonInteractiveSSR
					placeholder="Search"
					advancedSearchUrl={`${CONTEXT_PATH}/search`}
					shouldFillContainer
				/>
			) : (
				<NavigationSearchDialog theme={theme} clearStickySearchRef={resetStickySearchRef} />
			)}
		</StyledContainer>
	);
};
